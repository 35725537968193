import React from 'react'
import Layout, { Container } from 'src/components/Layout'
import styled from '@emotion/styled'
import peg, { responsive } from 'src/pegTheme'
import { Title } from 'src/components/typography';
import { graphql } from 'gatsby'
import { Helmet } from 'react-helmet'

export default ({ data: { markdownRemark: { frontmatter, html } } }) => (
  <Layout>
    <Helmet>
      <title>{ frontmatter.title } | Peg</title>
    </Helmet>
    <TersmContainer>
      <Title left>{ frontmatter.title }</Title>
      <em>Last updated { frontmatter.modified }</em>

      <div
        dangerouslySetInnerHTML={{ __html: html }}
      />
    </TersmContainer>
  </Layout>
)

export const pageQuery = graphql`
  query($path: String!) {
    markdownRemark(frontmatter: { path: { eq: $path } }) {
      html
      frontmatter {
        modified(formatString: "MMMM DD, YYYY")
        title
      }
    }
  }
`

const TersmContainer = styled(Container)`
  ${ responsive('padding', { d: `${ peg.spacing('m') } ${ peg.spacing('xl') } ${ peg.spacing('xl') }`, m: peg.spacing('m') }) }
  line-height: 200%;

  p, li {
    font-weight: ${ peg.get('fontWeight.light') };
    color: ${ peg.color('grey.dark') };
  }

  h2 {
    font-size: ${ peg.fs('headLg') };
  }

  h3 {
    font-size: ${ peg.fs('headSm') };
  }

  ol ol {
    list-style-type: lower-alpha;
  }
`